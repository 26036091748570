import {NgModule} from "@angular/core";
import {NavigationComponent} from "./components/navigation/navigation.component";
import {ProfileIconComponent} from "./components/profile-icon/profile-icon.component";
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
import {ClientSwitcherComponent} from "./components/client-switcher/client-switcher.component";
import {SharedModule} from "../shared/shared.module";
import { LayoutComponent } from './components/layout/layout.component';
import { AnimalsModule } from "../modules/animals/animals.module";
import { ModulesModule } from '../modules/modules.module';

@NgModule({
  declarations: [
    NavigationComponent,
    ProfileIconComponent,
    BreadcrumbComponent,
    ClientSwitcherComponent,
    LayoutComponent
  ],
  imports: [SharedModule, AnimalsModule, ModulesModule],
  exports: [
    BreadcrumbComponent,
    NavigationComponent
  ]
})
export class LayoutModule {
}