import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AnimalRepository} from '../animals/states/animal.repository';
import {TranslocoService} from '@ngneat/transloco';
import {AnimalAddService} from '../animals/services/animal-add.service';
import {take, takeWhile} from 'rxjs';
import {AnimalModel} from '../animals/models/animal.model';
import {AnimalWhereaboutsRepository} from "../animals/states/animal-whereabouts.repository";
import {HelperService} from "../../shared/services/helper.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  alive: boolean = true;
  value = 0;
  data: any;
  options: any;
  addDialogVisible: boolean = false;
  newAnimals: AnimalModel[] = [];
  maxLastEditedAnimals = 20;
  whereabouts: any;

  constructor(
    private _animalRepository: AnimalRepository,
    private _animalWhereaboutsRepository: AnimalWhereaboutsRepository,
    private _translocoService: TranslocoService,
    private _animalAddService: AnimalAddService,
    private _cdr: ChangeDetectorRef,
    private _helperService: HelperService
  ) {
  }

  ngOnInit(): void {
    this._animalAddService.addDialogVisible.pipe(takeWhile(() => this.alive)).subscribe((openModal) => {
      this.addDialogVisible = openModal;
    });

    this._animalWhereaboutsRepository.getAll().pipe(take(1)).subscribe({
      next: (whereabouts) => {
        if (whereabouts && whereabouts.length > 0) {
          this.whereabouts = whereabouts.reduce((acc: any, whereabout: any) => {
            acc.set(whereabout.animal?.id, whereabout);
            return acc;
          }, new Map<number, typeof whereabouts[0]>());
        }
      }
    });

    this._animalRepository.getAll().subscribe((animals: AnimalModel[]) => {
      const categoryCounts: { [key: string]: number } = animals.reduce((counts: { [key: string]: number }, animal) => {
        if (animal.category !== null) {
          if (!counts[this._translocoService.translate('animal.category.' + animal.category.toUpperCase())]) {
            counts[this._translocoService.translate('animal.category.' + animal.category.toUpperCase())] = 0;
          }
          counts[this._translocoService.translate('animal.category.' + animal.category.toUpperCase())]++;
        }
        return counts;
      }, {});

      this.data = {
        labels: Object.keys(categoryCounts),
        datasets: [
          {
            data: Object.values(categoryCounts)
          }
        ]
      };

      if (animals.length > 0) {
        this.getLastEditedAnimals(animals);
      } else {
        this.newAnimals = [];
      }
    });
  }

  getDateFormat(): string {
    return this._helperService.getDateStringByLang();
  }

  getLastEditedAnimals(animals: AnimalModel[]): void {
    const sortedAnimals = animals.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });

    this.newAnimals = sortedAnimals.slice(0, this.maxLastEditedAnimals);
  }

  toggleAddDialog() {
    this._animalAddService.toggleAddDialog(true);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  protected readonly HelperService = HelperService;
}
