<p-dialog
  [(visible)]="moveDialogVisible"
  [style]="{ width: '50vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
>
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    {{ 'animalEdit.move.headline' | transloco }}
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column">
      <p-messages severity="info">
        <ng-template pTemplate>
          <i class="pi pi-info"></i>
          <div class="ml-2">
            <p>{{ 'animalEdit.move.info' | transloco }}</p>
            <p>{{ 'animalEdit.move.consequences' | transloco }}</p>
            <ul>
              <li>{{ 'animalEdit.move.consequencesList.animal' | transloco }}</li>
              <li>{{ 'animalEdit.move.consequencesList.animalBookNumber' | transloco }}</li>
              <li>{{ 'animalEdit.move.consequencesList.animalOrigin' | transloco }}</li>
              <li>{{ 'animalEdit.move.consequencesList.animalWhereabout' | transloco }}</li>
            </ul>
          </div>
        </ng-template>
      </p-messages>
      <div class="card">
        <div class="flex flex-wrap gap-4">
          <div class="flex-auto">
            <label for="animalShelter" class="w-100">{{ 'animalEdit.move.shelter' | transloco }}</label>
            <p-dropdown
              class="w-full inline-flex h-3rem"
              appendTo="body"
              id="animalShelter"
              [options]="shelters"
              [filter]="true"
              [showClear]="false"
              [optionLabel]="'title'"
              [dataKey]="'id'"
              [(ngModel)]="selectedShelter"
              [ngModelOptions]="{standalone: true}"
            ></p-dropdown>
          </div>
          <div class="flex-auto">
            <label for="selectedDate" class="w-100">{{ 'animalEdit.move.date' | transloco }}</label>
            <p-calendar
              class="w-full inline-flex h-3rem"
              appendTo="body"
              id="selectedDate"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              [required]="true"
              [(ngModel)]="selectedDate"
              [ngModelOptions]="{standalone: true}"
            ></p-calendar>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1 text-center">
        <button class="p-button p-button-success" (click)="saveChanges()" [disabled]="!selectedShelter || !selectedDate">
          {{ 'animalEdit.general.saveChanges' | transloco }}
        </button>
      </div>
    </div>
    <div class="flex-end">
      <span class="required">* {{ 'animalEdit.general.required' | transloco }}</span>
    </div>
  </ng-template>
</p-dialog>
