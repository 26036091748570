import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { PersonTitleModel } from 'src/app/shared/models/person-title.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ContactRepository } from '../../states/contact.repository';
import { ContactModel } from '../../models/contact.model';
import { WaitingDialogService } from 'src/app/shared/services/waiting-dialog.service';
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-choose-contact',
  templateUrl: './choose-contact.component.html',
  styleUrls: ['./choose-contact.component.scss']
})
export class ChooseContactComponent implements OnInit {
  @Input() newContact: ContactModel = {} as ContactModel;
  @Input() isExistingContact: boolean = false;
  personSalutationOptions: PersonTitleModel[] = [];
  contacts: any[] = [];
  searchTerm: string = '';
  showForm: boolean = false;
  showSearchModal: boolean = false;
  editableFields: { [key: string]: boolean } = {};
  validationError: string = '';
  objectKeys = Object.keys;


  @Output() newContactUpdated = new EventEmitter<any>();
  @Output() validationStatusChanged = new EventEmitter<boolean>();
  @Output() existingContactUpdated = new EventEmitter<boolean>();
  @Output() existingContactSelected = new EventEmitter<ContactModel>();

  constructor(
    private _helperService: HelperService,
    private _contactRepository: ContactRepository,
    private _waitingDialogService: WaitingDialogService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.personSalutationOptions = this._helperService.getSalutationOptions();
    if (Object.keys(this.newContact).length === 0) {
      this.validationStatusChanged.emit(true);
    } else {
      if (this.newContact.birthday) {
        const birthdayValue = this.newContact.birthday;
        const formattedBirthday = birthdayValue ? new Date(birthdayValue) : null;
        this.newContact.birthday = formattedBirthday;
      }
      this.validateForm();
    }
  }

  openSearchModal(): void {
    this.showSearchModal = true;
  }

  closeSearchModal(): void {
    this.showSearchModal = false;
    this.searchTerm = '';
    this.contacts = [];
  }

  onSearch(): void {
    if (this.searchTerm.trim() && this.searchTerm.length > 2) {
      this._waitingDialogService.showWaitingDialog(true);
      this._contactRepository.searchContacts(this.searchTerm).subscribe(
        (results: any[]) => {
          this.contacts = results;
          this.cdr.detectChanges();
          this.ngZone.run(() => {
            this._waitingDialogService.showWaitingDialog(false);
          });
        },
        (error) => {
          console.error('Error fetching contacts', error);
          this.contacts = [];
          this.cdr.detectChanges();
          this.ngZone.run(() => {
            this._waitingDialogService.showWaitingDialog(false);
          });
        }
      );
    }
  }

  takeContact(contact: any): void {
    this.newContact = { ...contact };
    this.showForm = true;
    this.isExistingContact = true;
    this.editableFields = {};
    this.validationStatusChanged.emit(true);
    this.existingContactSelected.emit(this.newContact);
    this.closeSearchModal();
    this.validateForm();
  }

  onInputChange(): void {
    if (this.isExistingContact && Object.keys(this.editableFields).length > 0) {
      this.existingContactUpdated.emit(true);
    }
    if (this.editableFields['birthday'] && this.newContact.birthday) {
      this.newContact.birthday = this._helperService.normalizeDate(
        this.newContact.birthday
      );
    }
    this.newContactUpdated.emit(this.newContact);
    this.validateForm();
  }

  enableField(fieldName: string): void {
    this.editableFields[fieldName] = true;
  }

  createNewContact(): void {
    this.newContact = {} as ContactModel;
    this.showForm = true;
    this.isExistingContact = false;
    this.editableFields = {};
    this.existingContactSelected.emit({} as ContactModel);
    this.existingContactUpdated.emit(false);
    this.newContactUpdated.emit(this.newContact);
    this.validationStatusChanged.emit(false);
    this.validationError = '';
  }

  validateForm(): void {
    if (this.newContact) {
      const isValid = !!(this.newContact.firstName || this.newContact.lastName || this.newContact.companyName);
      this.validationError = isValid ? '' : 'Bitte geben Sie mindestens Vorname, Nachname oder Firmenname an.';
      this.validationStatusChanged.emit(isValid);
    }
  }

  protected readonly formatDate = formatDate;
  protected readonly Date = Date;
}
