import { Directive, OnInit } from '@angular/core';
import { Calendar } from 'primeng/calendar';

@Directive({
  selector: '[appCalendarMaxDate]'
})
export class CalendarMaxDateDirective implements OnInit {
  constructor(private calendar: Calendar) {}

  ngOnInit(): void {
    // Setze maxDate auf das aktuelle Datum
    this.calendar.maxDate = new Date();
  }
}
