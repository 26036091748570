import {createStore} from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  setEntities,
  updateEntities,
  withActiveId,
  selectEntity,
  upsertEntities
} from '@ngneat/elf-entities';
import {withRequestsCache, withRequestsStatus} from '@ngneat/elf-requests';
import {Injectable} from "@angular/core";
import {AnimalModel} from "../models/animal.model";
import {RestService} from "../../../shared/services/rest.service";
import {AnimalService} from '../services/animal.service';
import {map, Observable, shareReplay, take} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {ToastService} from 'src/app/shared/services/toast.service';
import {UserRepository} from "../../user/states/user.repository";
import { AnimalOriginModel } from '../models/animal-origin.model';

const storeName = 'animals';

export const store = createStore(
  {name: storeName},
  withEntities<AnimalModel>(),
  withActiveId(),
  withRequestsCache<typeof storeName>(),
  withRequestsStatus<typeof storeName>()
);

@Injectable({providedIn: 'root'})
export class AnimalRepository {
  animals$ = store.pipe(selectAllEntities());

  constructor(
    private _restService: RestService,
    private _animalService: AnimalService,
    private _translateService: TranslocoService,
    private _toast: ToastService,
    private _userRepository: UserRepository
  ) {
    // silence is golden
  }

  dataLoaded$ = this.animals$.pipe(
    map(animals => animals.length > 0),
    shareReplay(1),
  );

  getById(id: number) {
    store.pipe(selectEntity(id)).pipe(take(1)).subscribe(animal => {
      if (animal) {
        this._restService.httpGet('/animals/' + id).pipe(take(1)).subscribe((data: AnimalModel) => {
          store.update(upsertEntities([data]));
        });
      }
    });

    return store.pipe(selectEntity(id));
  }

  getByShelter(id: number) {
    this._restService.httpGet('/animals?pagination=false&animalShelter.id=' + id).pipe(take(1)).subscribe((data: any) => {
      if (data) {
        data.forEach((entity: any) => {
          entity.category = this._animalService.getAnimalsCategoryKeyOfValue(entity.category);
        })
        store.update(setEntities([]));
        store.update(setEntities(data));
      }
    });
  }

  getAll(): Observable<AnimalModel[]> {
    this._userRepository.getAnimalShelterId().pipe(take(1)).subscribe((animalShelterId) => {
      if (animalShelterId) {
        this.getByShelter(animalShelterId);
      }
    });

    return this.animals$;
  }

  async create(formGroupValues: any): Promise<number | null> {
    return new Promise<number | null>((resolve, reject) => {
      this._restService.httpPost('/animals', formGroupValues).subscribe(
        (animal: AnimalModel) => {
          if (animal.category) {
            animal.category = animal.category.toUpperCase();
          }
          store.update(upsertEntities([animal]));
          resolve(animal.id);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  update(id: AnimalModel['id'], animal: Partial<AnimalModel> | any): Promise<void> {
    return new Promise((resolve, reject) => {
      this._restService.httpPut('/animals/' + id, animal).subscribe(
        (response) => {
          store.update(updateEntities(id, response));
          this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
          resolve();
        },
        (error) => {
          this._toast.showError(this._translateService.translate('animalEdit.toast.saveError'));
          reject(error);
        }
      );
    });
  }

  searchAnimals(searchTerm: string, page: number = 1): Observable<AnimalModel[]> {
    return this._restService.httpGet('/animals?page=' + page + '&search=' + searchTerm).pipe(take(1));
  }

  setSyncWithWebsite(id: number, checked: boolean) {
    this._restService.httpPut('/animals/' + id, {syncWithWebsite: checked}).subscribe(() => {
      store.update(updateEntities(id, {syncWithWebsite: checked}));
      this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
    });
  }

  setSanctuary(id: number, checked: boolean) {
    this._restService.httpPut('/animals/' + id, {sanctuary: checked}).subscribe(() => {
      store.update(updateEntities(id, {sanctuary: checked}));
      this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
    });
  }

  setFosterHomeWanted(id: number, checked: boolean) {
    this._restService.httpPut('/animals/' + id, {fosterHomeWanted: checked}).subscribe(() => {
      store.update(updateEntities(id, {fosterHomeWanted: checked}));
      this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
    });
  }

  setSponsored(id: number, checked: boolean) {
    this._restService.httpPut('/animals/' + id, {sponsored: checked}).subscribe(() => {
      store.update(updateEntities(id, {sponsored: checked}));
      this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
    });
  }

  setOutdoor(id: number, checked: boolean) {
    this._restService.httpPut('/animals/' + id, {outdoor: checked}).subscribe(() => {
      store.update(updateEntities(id, {outdoor: checked}));
      this._toast.showSuccess(this._translateService.translate('animalEdit.toast.saveSuccess'));
    });
  }

  addOrigin(animalId: number, newOrigin: AnimalOriginModel): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getById(animalId).pipe(take(1)).subscribe((animal) => {
        if (animal) {
          const updatedOrigins = [...(animal.origins || []), newOrigin];
            this.update(animalId, { origins: updatedOrigins })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              console.error('Error adding origin:', error);
              reject(error);
            });
        } else {
          const error = new Error(`Animal with ID ${animalId} not found`);
          console.error(error.message);
          reject(error);
        }
      });
    });
  }

}
