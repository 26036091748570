<p-dialog
  [(visible)]="visible"
  [style]="{ width: '90vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
>
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    <div class="flex align-items-center mb-3">
      <div class="px-4">
        <app-animal-current-category
          [animal]="animal"
        ></app-animal-current-category>
      </div>
      <div class="flex px-4 animal-name-headline">
        {{ animal?.displayName }}
      </div>
      <div class="flex px-4 animal-key">
        <img src="./assets/images/icon_key_green.svg" alt="key"/>
        <p>{{ animal?.bookNumber }} <span *ngIf="animal?.oldBookNumber">({{ animal?.oldBookNumber }})</span></p>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content" *ngIf="animal">
    <form [formGroup]="formGroup">
      <div class="flex flex-row details-view">
        <div class="flex-1">
          <p-accordion [activeIndex]="[0, 1]" [multiple]="true">
            <p-accordionTab
              [header]="'animalEdit.general.basicData.headline' | transloco"
            >
              <div class="grid">
                <div class="col-6">
                  <label for="oldBookNumber">{{ 'animalEdit.general.basicData.oldBookNumber' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="oldBookNumber"
                          formControlName="oldBookNumber"/>
                </div>
                <div class="col-6">
                  <label for="gender"
                  >{{ 'animalEdit.general.basicData.sex' | transloco }}
                    <span class="required">*</span></label
                  >
                  <p-dropdown
                      class="p-inputdropdown-sm w-full"
                      id="gender"
                      [options]="genderOptions"
                      optionLabel="name"
                      optionValue="key"
                      formControlName="sex"
                      appendTo="body"
                  ></p-dropdown>
                </div>
                <div class="col-6">
                  <label for="animalType"
                  >{{
                          'animalEdit.general.basicData.category' | transloco
                    }}
                    <span class="required">*</span></label
                  >
                  <p-dropdown
                      class="p-inputdropdown-sm w-full"
                      id="animalCategory"
                      [options]="animalCategoryOptions"
                      optionLabel="name"
                      optionValue="value"
                      formControlName="category"
                      (onChange)="setCharacteristicOptions($event.value)"
                      appendTo="body"
                  ></p-dropdown>
                </div>
                <div class="col-6">
                  <label for="animalType"
                  >{{ 'animalEdit.general.basicData.type' | transloco }}
                    <span class="required">*</span></label
                  >
                  <p-dropdown
                      class="p-inputdropdown-sm w-full"
                      id="animalType"
                      [options]="animalTypeOptions"
                      optionLabel="name"
                      optionValue="value"
                      formControlName="type"
                      appendTo="body"
                  ></p-dropdown>
                </div>
                <div class="col-4">
                  <label for="name">{{ 'animalEdit.general.basicData.name' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="name" formControlName="name"/>
                </div>
                <div class="col-4">
                  <label for="nameOld">{{ 'animalEdit.general.basicData.nameOld' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="nameOld"
                          formControlName="nameOld"/>
                </div>
                <div class="col-4">
                  <label for="nameNew">{{ 'animalEdit.general.basicData.nameNew' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="nameNew"
                          formControlName="nameNew"/>
                </div>
                <div class="col-4">
                  <label for="mother">{{ 'animalEdit.general.basicData.mother' | transloco }}</label>
                  <div class="p-inputgroup">
                    <input type="text" pInputText class="p-inputtext-sm w-full" [value]="mother?.name" disabled/>
                    <button pButton type="button" icon="pi pi-search" class="p-button-rounded p-button-text p-button-sm"
                            (click)="openMotherSearchDialog()"></button>
                  </div>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab
              [header]="
                'animalEdit.general.specifications.headline' | transloco
              "
            >
              <div class="grid">
                <div class="col-12">
                  <label for="birthDate">{{ 'animalEdit.general.specifications.birthday' | transloco }}</label>
                  <div class="flex">
                    <div class="flex align-items-center p-2">
                      {{ 'animalEdit.general.specifications.approx' | transloco }}
                      <p-inputSwitch formControlName="birthApprox"></p-inputSwitch>
                    </div>
                    <div class="flex align-items-center p-2">
                      {{ 'animalEdit.general.specifications.date' | transloco }}
                    </div>
                    <div class="flex align-items-center p-2">
                      <p-inputSwitch formControlName="birthdayType"></p-inputSwitch>
                    </div>
                    <div class="flex align-items-center p-2">
                      {{ 'animalEdit.general.specifications.year' | transloco }}
                    </div>
                    <div class="flex-1 align-items-end">
                      <p-calendar
                        class="w-full p-inputcalendar-sm"
                        id="birthYear"
                        formControlName="birthYear"
                        view="year"
                        dateFormat="yy"
                        [showIcon]="true"
                        *ngIf="
                          formGroup.get('birthdayType')?.value === true">
                      </p-calendar>
                      <p-calendar
                        class="w-full p-inputcalendar-sm"
                        id="birthDate"
                        formControlName="birthday"
                        dateFormat="dd.mm.yy"
                        [showIcon]="true"
                        *ngIf="
                          formGroup.get('birthdayType')?.value === false
                        "
                      ></p-calendar>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label for="breed">{{
                          'animalEdit.general.specifications.breed' | transloco
                    }}</label>
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="breed"
                    formControlName="breed"
                  />
                </div>
                <div class="col-6">
                  <label for="coatType">{{ 'animalEdit.general.specifications.coatType' | transloco }}</label>
                  <p-multiSelect
                      class="p-inputdropdown-sm w-full"
                      id="coatType"
                      [options]="coatTypeOptions"
                      optionLabel="name"
                      optionValue="value"
                      formControlName="coatType"
                      [style]="{'width':'100%'}"
                      [panelStyle]="{'width':'100%'}"
                      appendTo="body"
                  ></p-multiSelect>
                </div>
                <div class="col-6">
                  <label for="coatColor">{{ 'animalEdit.general.specifications.coatColor' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="coatColor"
                          formControlName="coatColor"/>
                </div>
                <div class="col-6">
                  <label for="size">{{ 'animalEdit.general.specifications.size' | transloco }}
                  </label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="size" formControlName="size"/>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div class="flex-1">
          <p-accordion [activeIndex]="[0, 1]" [multiple]="true">
            <p-accordionTab
              [header]="'animalEdit.general.numbers.headline' | transloco"
            >
            <div class="grid">
              <div class="col-6">
                <label for="transponderNumber">{{
                    'animalEdit.general.numbers.transponderNumber' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="transponderNumber"
                    formControlName="transponderNumber"
                  />
                  <app-barcode-scanner
                    [bindTo]="'transponderNumber'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="lifeNumber">{{
                    'animalEdit.general.numbers.lifeNumber' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="lifeNumber"
                    formControlName="lifeNumber"
                  />
                  <app-barcode-scanner
                    [bindTo]="'lifeNumber'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="petPassportNumber">{{
                    'animalAdd.general.numbers.petPassportNumber' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="petPassportNumber"
                    formControlName="petPassportNumber"
                  />
                  <app-barcode-scanner
                    [bindTo]="'petPassportNumber'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="equinePassportNumber">{{
                    'animalAdd.general.numbers.equinePassportNumber' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="equinePassportNumber"
                    formControlName="equinePassportNumber"
                  />
                  <app-barcode-scanner
                    [bindTo]="'equinePassportNumber'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="ringNumber">{{
                    'animalEdit.general.numbers.ringNumber' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="ringNumber"
                    formControlName="ringNumber"
                  />
                  <app-barcode-scanner
                    [bindTo]="'ringNumber'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="earTag">{{
                    'animalEdit.general.numbers.earTagNumber' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="earTag"
                    formControlName="earTag"
                  />
                  <app-barcode-scanner
                    [bindTo]="'earTag'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="tattoo">{{
                    'animalEdit.general.numbers.tattoo' | transloco
                  }}</label>
                <div class="p-inputgroup">
                  <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm w-full"
                    id="tattoo"
                    formControlName="tattoo"
                  />
                  <app-barcode-scanner
                    [bindTo]="'tattoo'"
                    (barcodeScanned)="handleBarcodeScanned($event)"
                  ></app-barcode-scanner>
                </div>
              </div>
              <div class="col-6">
                <label for="tattooLocation">{{
                    'animalEdit.general.numbers.tattooLocation' | transloco
                  }}</label>
                <input
                  type="text"
                  pInputText
                  class="p-inputtext-sm w-full"
                  id="tattooLocation"
                  formControlName="tattooLocation"
                />
              </div>
            </div>
            
            </p-accordionTab>
            <p-accordionTab
              [header]="
                'animalEdit.general.specialInformations.headline'
                  | transloco
              "
            >
              <div class="grid">
                <div class="col-12">
                  <label for="specialInformation">{{
                          'animalEdit.general.specialInformations.specialInformation'
                              | transloco
                    }}</label>
                  <p-editor id="specialInformation" formControlName="specialInformation"
                            [style]="{ height: '200px' }"></p-editor>
                </div>
                <div class="col-2 p-0">
                  <label class="py-1" for="castrated">
                    {{ 'animalEdit.general.specialInformations.castrated' | transloco }}
                  </label>
                  <p-inputSwitch formControlName="castrated" id="castrated" inputId="castrated"></p-inputSwitch>
                </div>
                <div class="col-5 p-0">
                  <div class="flex p-0">
                    <div class="col-12 p-0">
                      <label class="py-1" for="castratedDate">
                        {{ 'animalEdit.general.specialInformations.castratedDate' | transloco }}
                      </label>
                    </div>
                  </div>
                  <div class="flex p-0">
                    <div class="col-10 p-0">
                      <p-calendar class="w-full p-inputcalendar-sm"
                                  id="castratedDate"
                                  formControlName="castratedDate"
                                  dateFormat="dd.mm.yy"
                                  [showIcon]="true"
                                  appendTo="body"
                      ></p-calendar>
                    </div>
                    <div class="col-2 p-0">
                      <button *ngIf="formGroup.get('castratedDate')?.value" pButton type="button" icon="pi pi-times"
                              class="p-button-rounded p-button-text p-button-sm"
                              (click)="formGroup.get('castratedDate')?.setValue(null)"></button>
                    </div>
                  </div>
                </div>
                <div class="col-5 p-0">
                  <div class="flex p-0">
                    <div class="col-12 p-0">
                      <label class="py-1" for="sterilizedDate">
                        {{ 'animalEdit.general.specialInformations.sterilizedDate' | transloco }}
                      </label>
                    </div>
                  </div>
                  <div class="flex p-0">
                    <div class="col-10 p-0">
                      <p-calendar class="w-full p-inputcalendar-sm" id="sterilizedDate"
                                  formControlName="sterilizedDate"
                                  dateFormat="dd.mm.yy" [showIcon]="true"
                                  appendTo="body"
                      ></p-calendar>
                    </div>
                    <div class="col-2 p-0">
                      <button *ngIf="formGroup.get('sterilizedDate')?.value" pButton type="button"
                              icon="pi pi-times" class="p-button-rounded p-button-text p-button-sm"
                              (click)="formGroup.get('sterilizedDate')?.setValue(null)"></button>
                    </div>
                  </div>
                </div>
                <div class="col-5 p-0">
                  <div class="flex p-0">
                    <div class="col-12 p-0">
                      <label class="py-1" for="chemicalCastratedDate">
                        {{ 'animalEdit.general.specialInformations.chemicalCastratedDate' | transloco }}
                      </label>
                    </div>
                  </div>
                  <div class="flex p-0">
                    <div class="col-10 p-0">
                      <p-calendar class="w-full p-inputcalendar-sm" id="chemicalCastratedDate"
                                  formControlName="chemicalCastratedDate"
                                  dateFormat="dd.mm.yy" [showIcon]="true"
                                  appendTo="body"></p-calendar>
                    </div>
                    <div class="col-2 p-0">
                      <button *ngIf="formGroup.get('chemicalCastratedDate')?.value" pButton type="button"
                              icon="pi pi-times" class="p-button-rounded p-button-text p-button-sm"
                              (click)="formGroup.get('chemicalCastratedDate')?.setValue(null)"></button>
                    </div>
                  </div>
                </div>
                <div class="col-7 p-0">
                  <label class="py-1"
                          for="castratedNote">{{ 'animalAdd.general.specialInformations.castratedNote' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="castratedNote"
                          formControlName="castratedNote"/>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
      <div class="flex flex-row details-view">
        <div class="flex-1">
          <p-accordion [activeIndex]="[0,1]" [multiple]="true">
            <p-accordionTab [header]="'animalEdit.general.websiteInformations.headline' | transloco">
              <div class="grid">
                <div class="col-12">
                  <label
                    for="externalShortDescription">{{ 'animalEdit.general.websiteInformations.externalShortDescription' | transloco }}</label>
                  <input type="text" pInputText class="p-inputtext-sm w-full" id="externalShortDescription"
                          formControlName="externalShortDescription"/>
                </div>
                <div class="col-12">
                  <label
                    for="externalDescription">{{ 'animalEdit.general.websiteInformations.externalDescription' | transloco }}</label>
                  <p-editor id="externalDescription" formControlName="externalDescription"
                            [style]="{ height: '200px' }"></p-editor>
                </div>
                <div class="col-6">
                  <label class="py-1">
                    {{ 'animalEdit.general.specialInformations.characteristics.headline' | transloco }}
                    ({{ 'animalEdit.general.specialInformations.characteristics.internal' | transloco }})
                  </label>
                  <p-multiSelect
                    class="p-inputdropdown-sm w-full"
                    id="internalDetails"
                    [options]="characteristicsOptions"
                    optionLabel="name"
                    optionValue="value"
                    formControlName="internalDetails"
                    [style]="{'width':'400px'}"
                    [panelStyle]="{'width':'400px'}"
                    appendTo="body"
                  ></p-multiSelect>
                </div>
                <div class="col-6">
                  <label class="py-1">
                    {{ 'animalEdit.general.specialInformations.characteristics.headline' | transloco }}
                    ({{ 'animalEdit.general.specialInformations.characteristics.external' | transloco }})
                  </label>
                  <p-multiSelect
                    class="p-inputdropdown-sm w-full"
                    id="externalDetails"
                    [options]="characteristicsOptions"
                    optionLabel="name"
                    optionValue="value"
                    formControlName="externalDetails"
                    [style]="{'width':'400px'}"
                    [panelStyle]="{'width':'400px'}"
                    appendTo="body"
                  ></p-multiSelect>
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <label class="py-1">{{ 'animalEdit.general.specialInformations.hints' | transloco }}</label>
                  <p-multiSelect
                    class="p-inputdropdown-sm w-full"
                    id="hints"
                    [options]="hintsOptions"
                    optionLabel="name"
                    optionValue="value"
                    formControlName="hints"
                    [style]="{'width':'400px'}"
                    [panelStyle]="{'width':'400px'}"
                    appendTo="body"
                  ></p-multiSelect>
                </div>
              </div>
              <div class="col-12">
                <label class="py-1" for="websiteMetaDescription">
                  {{ 'animalAdd.general.websiteInformations.websiteMetaDescription' | transloco }}
                </label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="websiteMetaDescription"
                        formControlName="websiteMetaDescription"/>
              </div>
              <div class="col-12">
                <label class="py-1" for="websiteMetaTitle">
                  {{ 'animalAdd.general.websiteInformations.websiteMetaTitle' | transloco }}
                </label>
                <input type="text" pInputText class="p-inputtext-sm w-full" id="websiteMetaTitle"
                        formControlName="websiteMetaTitle"/>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1 text-center">
        <button class="p-button p-button-success" (click)="saveChanges()"
                [disabled]="formGroup.invalid">
          {{ 'animalEdit.general.saveChanges' | transloco }}
        </button>
      </div>
    </div>
    <div class="flex-end">
      <span class="required"
      >* {{ 'animalEdit.general.required' | transloco }}</span
      >
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="motherSearchDialogVisible"
  [style]="{ width: '90vw', height: '90vh' }"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="motherSearchDialogVisible = false">
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    <div class="flex align-items-center mb-3">
      <div class="flex px-4 animal-name-headline">
        {{ 'animalEdit.general.basicData.mother' | transloco }}
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-row w-full justify-content-center mb-2">
      <input type="text" class="w-full" pInputText [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}" placeholder="Suche nach Mutter">
      <button class="ml-1" pButton type="button" label="Suchen" (click)="onSearch()"></button>
    </div>
    <div class="flex-grow-scroll">
      <p-table *ngIf="animalSearchResult.length > 0" [value]="animalSearchResult" class="p-mt-4">
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>{{ 'animalAdd.general.basicData.name' | transloco }}</th>
            <th>{{ 'animalAdd.general.basicData.type' | transloco }}</th>
            <th>{{ 'animalAdd.general.basicData.bookNumber' | transloco }}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-animal>
          <tr>
            <td class="relative">
              <app-animal-image-container [animal]="animal" [preview]="true"></app-animal-image-container>
            </td>
            <td>{{ animal.name }}</td>
            <td>{{ animal.type }}</td>
            <td>{{ animal.bookNumber }}</td>
            <td class="flex flex-row-reverse">
              <p-button (click)="addMotherAnimal(animal)">
                {{ 'animalAdd.general.basicData.addMother' | transloco }}
              </p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>

</p-dialog>
