import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private menuItems: MenuItem[] = [];

  openChangelogBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.menuItems = [
      {
        label: 'Dashboard',
        icon: 'custom-icon home',
        routerLink: ['/']
      },
      {
        label: 'Tiere',
        icon: 'custom-icon animal',
        routerLink: ['/tiere']
      },
      // {
      //   label: 'Gutscheine',
      //   icon: 'custom-icon vouchers',
      //   routerLink: ['/gutscheine']
      // },
      // {
      //   label: 'Verträge',
      //   icon: 'custom-icon contracts',
      //   routerLink: ['/vertraege']
      // },
      // {
      //   label: 'Reportings',
      //   icon: 'custom-icon reports',
      //   routerLink: ['/reportings']
      // }
    ]
  }

  getMenuItems() {
    return this.menuItems;
  }
}
