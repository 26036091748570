import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from "../../../environment/environment";
import {switchMap, catchError} from 'rxjs/operators';
import {jwtDecode} from 'jwt-decode';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private readonly _http: HttpClient,
    private readonly _router: Router
  ) {
  }

  getAccessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  setAccessToken(accessToken: string): void {
    localStorage.setItem('accessToken', accessToken);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  setRefreshToken(token: string): void {
    localStorage.setItem('refreshToken', token);
  }

  setTokens(response: any): void {
    this.setAccessToken(response.token);
    this.setRefreshToken(response.refresh_token);
  }

  clearToken(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  isTokenExpired(token: string): boolean {
    const decoded: any = jwtDecode(token);
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decoded.exp);
    return expirationDate < new Date();
  }

  renewToken(): Observable<string | null> {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken || refreshToken === 'undefined') {
      return of(null);
    }

    return this._http.post<{ token: string }>(`${environment.apiUrl}/token/refresh`, { refresh_token: refreshToken }).pipe(
      switchMap(response => {
        const newToken = response.token;
        this.setAccessToken(newToken);
        return of(newToken);
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.clearToken();
          this._router.navigate(['/login']);
          return of(null);
        }
        return of(null);
      })
    );
  }
}
