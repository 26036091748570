import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import { TokenService } from '../services/token.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private tokenService: TokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !request.url.includes('refresh')) {
          return this.tokenService.renewToken().pipe(
            switchMap(newToken => {
              if (newToken) {
                return next.handle(request);
              } else {
                this.authService.logout();
                return throwError(error);
              }
            }),
            catchError(() => {
              this.authService.logout();
              return throwError(error);
            })
          );
        } else if (error.status === 404) {
          return throwError(error);
        }

        return throwError(error);
      })
    );
  }
}
