import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { RestService } from 'src/app/shared/services/rest.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent {
  scrollListenerAdded = false;
  isChangelogVisible = true;
  activeId: number | undefined;
  isScrolling: boolean = false;
  changelogData: any[] = [];

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private restService: RestService
  ) {}

  ngOnInit(): void {
    this.layoutService.openChangelogBehaviorSubject.subscribe((isOpen) => {
      this.isChangelogVisible = isOpen;
      this.isChangelogVisible = isOpen;
      if (isOpen) {
        this.waitForScrollContainerAndInitListener();
      }
    });

    this.restService.httpGet('/changelog_entries').pipe(take(1)).subscribe((data: any) => {
      this.changelogData = data.sort((a: any, b: any) => b.id - a.id);
      this.activeId = this.changelogData[0].id;
    });
  }

  private waitForScrollContainerAndInitListener(): void {
    const interval = setInterval(() => {
      if (this.scrollContainer?.nativeElement && !this.scrollListenerAdded) {
        this.initScrollListener();
        clearInterval(interval);
      }
    }, 50);
  }

  private initScrollListener(): void {
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
      this.renderer.listen(this.scrollContainer.nativeElement, 'scroll', this.onScroll.bind(this));
      this.scrollListenerAdded = true;
    }
  }

  onScroll(): void {
    if (this.isScrolling || !this.scrollContainer) {
      return;
    }
  
    const elements = this.scrollContainer.nativeElement.querySelectorAll('.log-entry');
    const containerRect = this.scrollContainer.nativeElement.getBoundingClientRect();
  
    let newActiveId: number | null = null;
  
    elements.forEach((element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
 
      const elementHeight = rect.height;
      const visibleHeight = Math.min(rect.bottom, containerRect.bottom) - Math.max(rect.top, containerRect.top);
  
      if (visibleHeight > elementHeight / 2) {
        const id = parseInt(element.getAttribute('id')?.split('-')[1] || '0', 10);
        if (id && (newActiveId === null || rect.top < containerRect.top)) {
          newActiveId = id;
        }
      }
    });
  
    if (newActiveId !== null && newActiveId !== this.activeId) {
      this.activeId = newActiveId;
    }
  }
  
  

  scrollTo(id: number): void {
    this.isScrolling = true;

    const element = document.getElementById(`log-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.activeId = id;

      setTimeout(() => {
        this.isScrolling = false;
      }, 500);
    }
  }

  isActive(id: number): boolean {
    return this.activeId === id;
  }

  resetProperties(): void {
    this.isChangelogVisible = false;
    this.activeId = 1;
    this.isScrolling = false;
    this.scrollListenerAdded = false;
  }

}
