import {NgModule} from "@angular/core";
import {LoginModule} from "./login/login.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {AnimalsModule} from "./animals/animals.module";
import { ContactModule } from './contact/contact.module';
import { ChangelogComponent } from './changelog/changelog/changelog.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    LoginModule,
    DashboardModule,
    AnimalsModule,
    ContactModule,
    SharedModule
  ],
  exports: [
    ChangelogComponent
  ],
  declarations: [
    ChangelogComponent
  ],
})
export class ModulesModule {
}